<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Operaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">
                    <router-link to="/tif">Tif</router-link>
                  </li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Operación</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                </ol>
              </div>
            </div>
          </div>
          <!-- /.container-fluid -->
        </section>
        <!-- Main content -->
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_tifoperaciones_export"
                        v-if="$store.getters.can('tif.operaciones.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        v-if="$store.getters.can('tif.operaciones.create')"
                        @click="crearForm('Crear')"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="users"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th>
                        Bloque
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="filtros.bloque"
                          placeholder="Bloque"
                          label="nombre"
                          :options="listasForms.bloques"
                          :filterable="false"
                          @search="buscarBloques"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>
                        Descripcion
                      </th>

                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="operacion in operaciones.data"
                      :key="operacion.id"
                    >
                      <td v-if="operacion.bloque">
                        {{ operacion.bloque.nombre }}
                      </td>
                      <td v-else></td>
                      <td>{{ operacion.Noperacion }}</td>

                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            v-if="$store.getters.can('tif.operaciones.show')"
                            @click="visual(operacion, 'Editar')"
                            class="btn btn-sm bg-info"
                          >
                            <i class="fas fa-eye"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="$store.getters.can('tif.operaciones.edit')"
                            @click="edit(operacion, 'Editar')"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="$store.getters.can('tif.operaciones.delete')"
                            @click="destroy(operacion)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="operaciones.total">
                  <p>
                    Mostrando del <b>{{ operaciones.from }}</b> al
                    <b>{{ operaciones.to }}</b> de un total:
                    <b>{{ operaciones.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="operaciones"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
        <TifOperacionesExport ref="TifOperacionesExport" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
import TifOperacionesExport from "./TifOperacionesExport";

export default {
  name: "OperacionesIndex",
  components: {
    pagination,
    Loading,
    vSelect,
    TifOperacionesExport,
  },
  data() {
    return {
      filtros: {
        bloque: null,
        bloque_id: null,
      },
      operaciones: {},
      form: {
        id: null,
      },
      listasForms: {
        bloques: [],
      },
      modal: {
        title: "",
        accion: "",
      },
      cargando: false,
    };
  },
  validations: {
    form: {
      bloque: {
        required,
      },
      descripcion: {
        required,
      },
    },
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      this.filtros.bloque_id = null;
      if (this.filtros.bloque) {
        this.filtros.bloque_id = this.filtros.bloque.id;
      }
      axios
        .get("/api/tif/operaciones?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.operaciones = response.data;
          this.cargando = false;
        });
    },

    crearForm(act) {
      return this.$router.push({
        name: "/Tif/OperacionesForm",
        params: {
          accion: act,
        },
      });
    },

    edit(operacion, act) {
      return this.$router.push({
        name: "/Tif/OperacionesForm",
        params: {
          data_edit: operacion,
          accion: act,
        },
      });
    },

    visual(operacion, act) {
      return this.$router.push({
        name: "/Tif/OperacionesForm",
        params: {
          data_edit: operacion,
          accion: act,
          act: 2,
        },
      });
    },

    show(idOperacion) {
      axios.get("/api/tif/operaciones/show/" + idOperacion).then((response) => {
        this.form = response.data;
      });
    },

    buscarBloques(search) {
      let me = this;
      me.listasForms.bloques = [];
      if (search != "") {
        var url = "api/admin/bloques/listaSearch?bloque=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.bloques = response.data;
          })
          .catch(function(e) {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    destroy(idOperacion) {
      this.$swal({
        title: "Esta seguro de eliminar este Item?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/tif/operaciones/" + idOperacion)
            .then((response) => {
              this.cargando = false;
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se elimino el item exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    back() {
      this.$refs.closeModal.click();
    },
  },
  mounted() {
    this.getIndex();
  },
};
</script>
